import axios from 'axios';
import Config from '../config';

const instance = axios.create({
  baseURL: Config.baseUrl,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': Config.envType !== 'PROD' ? '*' : undefined,
  },
});

export default instance;
