/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProperties {
  description?: string;
  lang?: string;
  // meta?: HTMLMetaElement[];
  title: string;
  path?: string;
}

const QUERIES = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    logos: allFile(filter: { ext: { eq: ".svg" }, name: { eq: "logo" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;

interface QueryResults {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
      siteUrl: string;
    };
  };
  logos: {
    edges: { node: { publicURL: string } }[];
  };
}

export const SEO = ({ description, lang = 'en', title, path }: SEOProperties): JSX.Element => {
  // export const SEO = ({ description = '', lang = 'en', meta = [], title }: SEOProperties): JSX.Element => {
  const { site, logos }: QueryResults = useStaticQuery(QUERIES);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={site.siteMetadata.title === title ? title : `%s | ${site.siteMetadata.title}`}
    >
      <meta
        name="title"
        content={site.siteMetadata.title === title ? title : `${title} | ${site.siteMetadata.title}`}
      />
      <meta name="description" content={description || site.siteMetadata.description} />
      <meta
        property="og:title"
        content={site.siteMetadata.title === title ? title : `${title} | ${site.siteMetadata.title}`}
      />
      <meta property="og:description" content={description || site.siteMetadata.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={path ? `${site.siteMetadata.siteUrl}/${path}` : site.siteMetadata.siteUrl} />
      {logos?.edges?.[0]?.node?.publicURL && (
        <meta name="image" content={`${site.siteMetadata.siteUrl}${logos.edges[0].node.publicURL}`} />
      )}
      {logos?.edges?.[0]?.node?.publicURL && (
        <meta property="og:image" content={`${site.siteMetadata.siteUrl}${logos.edges[0].node.publicURL}`} />
      )}
      {logos?.edges?.[0]?.node?.publicURL && (
        <script type="application/ld+json">
          {`{"@context": "https://schema.org/", "@type": "Organization", "url": "${site.siteMetadata.siteUrl}", "logo": "${site.siteMetadata.siteUrl}${logos.edges[0].node.publicURL}"}`}
        </script>
      )}
      <script type="text/javascript">
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "l9ri1rvsty");`}
      </script>
      <meta
        name="keywords"
        content="app development, mobile application, iphone app developer, android app developer, develop app, content management systems, application design"
      />
    </Helmet>
  );
};

export default SEO;
