import React from 'react';
import { Field, Form, Formik } from 'formik';
import FOOTER_CHEERS from '../../images/footer-cheers.png';
import ICON_INSTAGRAM from '../../images/footer-instagram.png';
import ICON_LINKEDIN from '../../images/footer-linkedin.svg';
import './Footer.scss';
import { submitMessage } from '../../apis';

interface Props {
  minimal?: true;
}

const Footer = ({ minimal }: Props): JSX.Element => (
  <footer id="contact-us" className={minimal ? 'mod-minimal' : ''}>
    <div className="contact-us-container">
      {!minimal && (
        <>
          <img src={FOOTER_CHEERS} alt="" />
          <div className="contact-us-content">
            <div className="contact-us-content-info">
              <h2 data-sal="fade" data-sal-duration="300">
                Let’s talk! <br /> +852 6103 2979
              </h2>
              <div className="contact-us-content-info-item" data-sal="fade" data-sal-duration="300">
                <p>Address:</p>
                <p>Unit 2B, 128 Wellington Street, Central, Hong Kong</p>
              </div>
              <div className="contact-us-content-info-item" data-sal="fade" data-sal-duration="300">
                <p>Email</p>
                <p>hello@app-bar.com</p>
              </div>
            </div>
            <div className="contact-us-content-form" data-sal="fade" data-sal-duration="300">
              <Formik
                initialValues={{ name: '', email: '', message: '', terms: false }}
                onSubmit={({ name, email, message, terms }) => {
                  submitMessage(name, email, message, terms)
                    // eslint-disable-next-line no-alert
                    .then(() => alert('Message successfully sent, thank you!'))
                    // eslint-disable-next-line no-alert
                    .catch(() => alert('Failed to sent message'));
                }}
              >
                {({ errors, touched, values }) => (
                  <Form>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Name"
                      validate={(value: string) => (value.length === 0 ? 'Required Field' : undefined)}
                    />
                    <span className="contact-us-error">{touched.name ? errors.name : ''}</span>

                    <Field
                      name="email"
                      type="email"
                      placeholder="Email"
                      validate={(value: string) => {
                        if (value.length === 0) {
                          return 'Required Field';
                        }
                        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                          return 'Invalid Email';
                        }
                        return undefined;
                      }}
                    />
                    <span className="contact-us-error">{touched.email ? errors.email : ''}</span>

                    <Field
                      name="message"
                      as="textarea"
                      placeholder="Message"
                      validate={(value: string) => (value.length === 0 ? 'Required Field' : undefined)}
                    />
                    <span className="contact-us-error">{touched.message ? errors.message : ''}</span>

                    <label htmlFor="checkbox-terms" className="contact-us-checkbox-wrapper">
                      <input type="none" style={{ display: 'none' }} />
                      <Field
                        id="checkbox-terms"
                        name="terms"
                        type="checkbox"
                        className={`contact-us-checkbox ${values.terms ? 'mod-checked' : ''}`}
                      />
                      <span className="contact-us-label">
                        I want to receive news, feature updates, discounts and offers from App Bar
                      </span>
                    </label>
                    <button type="submit">Send</button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </>
      )}
      <div className="contact-us-footer">
        <div className="contact-us-footer-left">
          <p data-sal="slide-up" data-sal-duration="300">
            All rights reserved © {new Date().getFullYear()} App Bar
          </p>
          <a href="https://www.linkedin.com/company/app-bar" target="_blank" rel="noreferrer">
            <img src={ICON_LINKEDIN} alt="LinkedIn" />
          </a>
          <a href="https://instagram.com/app_bar" target="_blank" rel="noreferrer">
            <img src={ICON_INSTAGRAM} alt="Instagram" />
          </a>
        </div>
        <button
          type="button"
          data-sal="slide-up"
          data-sal-duration="300"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          {' '}
        </button>
      </div>
    </div>
  </footer>
);

export default Footer;
