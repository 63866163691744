import React, { memo, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Link, navigate } from 'gatsby';
import './Header.scss';
import { useLocation } from '@reach/router';
import Logo from '../../images/logo.svg';

Modal.setAppElement('#___gatsby');

const SECTIONS = [
  { label: 'Home', link: '/' },
  { label: 'About', link: '/about/' },
  { label: 'Work', link: '/work/' },
  { label: 'Blog', link: '/blogs/' },
  { label: 'Join Us', link: '/join/' },
  { label: 'Contact', link: '/contact/' },
];

const Header = memo(() => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const lastScrollTopRef = useRef(lastScrollTop);
  const { pathname } = useLocation();

  // Show / Hide Header
  const updateVisibility = () => {
    const header = document.querySelector('.header');
    if (header) {
      if (lastScrollTopRef.current < window.scrollY && window.scrollY > 0) {
        header.classList.add('mod-hidden');
      } else {
        header.classList.remove('mod-hidden');
      }
    }
    lastScrollTopRef.current = window.scrollY;
    setLastScrollTop(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateVisibility);
    return () => window.removeEventListener('scroll', updateVisibility);
  }, []);

  // Modal
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <Modal isOpen={showMenu} className="header-mobile-modal" overlayClassName="header-mobile-modal-overlay">
        <div className="header-container">
          <Link to="/" className="header-logo">
            <img src={Logo} alt="Home" />
          </Link>
          <button type="button" onClick={() => setShowMenu(false)} className="header-mobile-menu-close">
            {' '}
          </button>
        </div>
        <div className="header-mobile-menu">
          <div className="header-mobile-menu-sections">
            {SECTIONS.map((section) => (
              <button
                key={section.label}
                className={`header-mobile-menu-section ${pathname === section.link ? 'mod-active' : ''}`}
                type="button"
                onClick={() => {
                  navigate(section.link);
                }}
              >
                {section.label}
              </button>
            ))}
          </div>
        </div>
      </Modal>

      <header className="header">
        <div className="header-container">
          <Link to="/" className="header-logo">
            <img src={Logo} alt="Home" />
          </Link>
          <div className="header-list">
            {SECTIONS.map((section) => (
              <a
                href={section.link}
                className={`header-list-btn ${pathname === section.link ? 'mod-active' : ''}`}
                key={section.label}
              >
                <span>{section.label}</span>
              </a>
            ))}
          </div>
          {/* <div className="header-list-highlight" /> */}
          <div className="header-list-mobile">
            <button type="button" onClick={() => setShowMenu(true)}>
              {' '}
            </button>
          </div>
        </div>
      </header>
    </>
  );
});

Header.displayName = 'Header';

export default Header;
