import React, { ReactNode, useEffect } from 'react';
import { SEO, ParticlesJS } from '../components';

import './layout.scss';

interface LayoutProperties {
  title?: string;
  path?: string;
  children: ReactNode;
}

const Layout = ({ title, path, children }: LayoutProperties): JSX.Element => {
  useEffect(() => {
    document.oncontextmenu = () => false;
  }, []);
  return (
    <>
      <SEO title={title || 'App Bar'} path={path} />
      <div className="container">
        {/* <ParticlesJS /> */}
        {children}
      </div>
    </>
  );
};

export default Layout;
