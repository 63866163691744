const getBaseUrl = (): string => {
  if (process.env.GATSBY_BASE_URL) {
    return process.env.GATSBY_BASE_URL;
  }
  return 'https://api.app-bar.com/';
};

const Config = {
  baseUrl: getBaseUrl(),
  envType: process.env.GATSBY_APP_ENV,
};

export default Config;
